@media only all and (max-width: 700px) {

	/* for 700px or less */
	body {
		-ms-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	main {
		position: relative;
		margin: 0 auto;
		background-color: var(--darkestblue);
		font-size: 1rem;
	}

	.block {
		opacity: 1;
		transform: translateX(0);
		transition: all 1s ease;
	}


	.iconbox img,
	.iconbox2 img,
	.iconbox3 img {
		height: 4rem;
		margin: auto auto 1rem auto;
	}

	.iconbox,
	.iconbox2,
	.iconbox3 {
		margin-bottom: 2rem;
	}

	.yellow-iconbox img {
		height: 4rem;
		margin: auto auto 1rem auto;
	}

	.yellow-iconbox {
		margin-bottom: 2rem;
	}

	.icons {
		margin: 0 0 0rem -0%;
	}

	header {
		position: relative;
		margin-bottom: -8rem;
	}

	#logo img {
		width: 13rem;
	}

	.navigation ul {
		display: none;
	}

	.languagebtn img {
		margin-top: 0rem;
	}

	.content {
		position: relative;
		padding: 2rem 10%;
		color: #fff;
	}

	#logo {
		padding: 1.5rem 2% 1rem 10%;
	}

	.flashcontent {
		margin: 1.5rem 0 10rem 0;
		padding: 2rem 10% 2rem 10%;
		width: 90%;
		height: 35rem;
	}

	.flash {
		width: 90%;
		margin: 0rem 0 1rem;
		padding: 0.8rem 10%;
	}

	.bgimgbox {
		margin-bottom: 17rem;
		padding: 3rem 10% 1rem 10%;
		background-color: var(--darkblue1);
		background-size: 300%;
		background-position: bottom;
		background-attachment: inherit;
	}

	.content-bild {
		padding: 0rem 10%;
		margin: -1rem 0 -1.5rem 0;
	}

	.content-white {
		padding: 2rem 10%;
	}

	.flashcontent {
		height: 50rem;
	}

	.flashcontent::before {

		height: 60.5rem;
	}


	footer {
		padding: 1rem 10%;
	}

	h1 {
		font-size: 2rem;
	}

	h1 span {}

	h2 {
		font-size: 1.4rem;
	}

	.bgimgbox h2 {
		font-size: 2rem;
	}

	header p {
		width: 70%;
	}

	.img-box {

		height: 22rem;
		margin: -17rem 0 -2rem;
	}

	.img-box2 {
		height: 22rem;
		margin: 0rem 0 -2rem;
	}

	h3 {
		font-size: 1.2rem;
		margin: 1rem 0 0.5rem 0;
	}

	h4 {}

	.iconbox {
		width: 100%;
		margin-right: -6.5%;
		padding: 3rem 3rem 2rem 2.5rem;
	}

	.yellow-iconbox {
		width: 100%;
		margin-right: -6.5%;
		padding: 3rem 3rem 2rem 2.5rem;
	}

	.iconbox2 {
		width: 100%;
		margin-right: -6.5%;
		padding: 3rem 3rem 2rem 2.5rem;
	}

	.iconbox3 {
		width: 100%;
		margin-right: -6.5%;
		padding: 3rem 3rem 2rem 2.5rem;
	}

	.icontests img {
		left: 10%;
	}

	.flex {
		display: block;
	}

	.left,
	.right {
		width: 100%;
	}

	.left {
		padding: 2rem 2% 3rem 10%;
	}

	.right {
		display: none;
	}

	footer .right {
		display: block;
		text-align: left;
		margin-top: 1rem;
	}

	.hide {
		display: block;
	}

	.hide_m {
		display: none;
	}

	article.content div {
		flex-direction: column;
	}
	article.content div div {
		flex: 100%;
		padding: 10px 0 10px 0;
	}


}