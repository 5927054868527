@media only all and (width <= 700px) {
  body {
    hyphens: auto;
  }

  main {
    background-color: var(--darkestblue);
    margin: 0 auto;
    font-size: 1rem;
    position: relative;
  }

  .block {
    opacity: 1;
    transition: all 1s;
    transform: translateX(0);
  }

  .iconbox img, .iconbox2 img, .iconbox3 img {
    height: 4rem;
    margin: auto auto 1rem;
  }

  .iconbox, .iconbox2, .iconbox3 {
    margin-bottom: 2rem;
  }

  .yellow-iconbox img {
    height: 4rem;
    margin: auto auto 1rem;
  }

  .yellow-iconbox {
    margin-bottom: 2rem;
  }

  .icons {
    margin: 0 0 0 -0%;
  }

  header {
    margin-bottom: -8rem;
    position: relative;
  }

  #logo img {
    width: 13rem;
  }

  .navigation ul {
    display: none;
  }

  .languagebtn img {
    margin-top: 0;
  }

  .content {
    color: #fff;
    padding: 2rem 10%;
    position: relative;
  }

  #logo {
    padding: 1.5rem 2% 1rem 10%;
  }

  .flashcontent {
    width: 90%;
    height: 35rem;
    margin: 1.5rem 0 10rem;
    padding: 2rem 10%;
  }

  .flash {
    width: 90%;
    margin: 0 0 1rem;
    padding: .8rem 10%;
  }

  .bgimgbox {
    background-color: var(--darkblue1);
    background-position: bottom;
    background-size: 300%;
    background-attachment: inherit;
    margin-bottom: 17rem;
    padding: 3rem 10% 1rem;
  }

  .content-bild {
    margin: -1rem 0 -1.5rem;
    padding: 0 10%;
  }

  .content-white {
    padding: 2rem 10%;
  }

  .flashcontent {
    height: 50rem;
  }

  .flashcontent:before {
    height: 60.5rem;
  }

  footer {
    padding: 1rem 10%;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  .bgimgbox h2 {
    font-size: 2rem;
  }

  header p {
    width: 70%;
  }

  .img-box {
    height: 22rem;
    margin: -17rem 0 -2rem;
  }

  .img-box2 {
    height: 22rem;
    margin: 0 0 -2rem;
  }

  h3 {
    margin: 1rem 0 .5rem;
    font-size: 1.2rem;
  }

  .iconbox, .yellow-iconbox, .iconbox2, .iconbox3 {
    width: 100%;
    margin-right: -6.5%;
    padding: 3rem 3rem 2rem 2.5rem;
  }

  .icontests img {
    left: 10%;
  }

  .flex {
    display: block;
  }

  .left, .right {
    width: 100%;
  }

  .left {
    padding: 2rem 2% 3rem 10%;
  }

  .right {
    display: none;
  }

  footer .right {
    text-align: left;
    margin-top: 1rem;
    display: block;
  }

  .hide {
    display: block;
  }

  .hide_m {
    display: none;
  }

  article.content div {
    flex-direction: column;
  }

  article.content div div {
    flex: 100%;
    padding: 10px 0;
  }
}
/*# sourceMappingURL=index.cf33fb26.css.map */
